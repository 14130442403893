import React, {useState, useEffect} from "react"
import { useLoginSession } from "../../stores/loginSession";
import { useBackend } from "../../utility/Backend";
import Backend from "../../utility/Backend";
import CustomModal from "../../components/CustomModal";
import ConfirmModal from "../../components/ConfirmModal";
import { useMutateByRegex } from "../../utility/Utilities";
import { useMediaQuery } from "react-responsive";
import ExportsTable from "./ExportsTable";
import "./Exports.css"
import {BsInfoCircle} from "react-icons/bs";
import {FaTrashAlt} from "react-icons/fa";

function Exports ({isOpen, onClose}) {
    
    const {token} = useLoginSession()
    const mutateByRegex = useMutateByRegex()
    const mobileDevice = useMediaQuery({maxWidth: 768})

    const [downloadIndex, setDownloadIndex] = useState([])
    const [exportToBeRemoved, setExportToBeRemoved] = useState(null)

    // this useEffect is for disabling the button for 5 secs in case the user clicks download 
    // and the status does not change to "processing straight away"
    useEffect(() => {
        const removeIsDownloading = setTimeout(() => {
            setDownloadIndex([]);
        }, 5000)
        return () => clearTimeout(removeIsDownloading)
    }, [downloadIndex])

    const apiQuery = {access_token: token, count: 25}
    const {data} = useBackend("/video_export", apiQuery, {
        dedupingInterval: 9900,
        refreshInterval: 10000,
    })

    if (!data) return null

    const onDownload = (event, mp4url, description, index) => {
        if (event) event.stopPropagation()
        setDownloadIndex([...downloadIndex, index]);
        // FIXME: Only enable for PPRO
        if (true) {
            event.preventDefault();

            // set name to the frist 40 characters of the description if set, otherwise
            // generate a random string of 10 characters as name of the export
            let name = description ? description.substring(0, 40).replace(/\s/g, "_") : Math.random().toString(36).substring(2, 15);

            const date = new Date();
            const dateString = date.getFullYear().toString().slice(-2) + "" +
                                (date.getMonth() + 1).toString().padStart(2, "0") + "" +
                                date.getDate().toString().padStart(2, "0") + "T" +
                                date.getHours().toString().padStart(2, "0") + "" +
                                date.getMinutes().toString().padStart(2, "0") + "_";

            window.top.postMessage({
                type: "ppro",
                mp4url: mp4url,
                token: "XXX", // Note: We don't need to get a token since the exports are public
                name: dateString + name
            }, "*");
            return;
        }
    }

    const removeExport = () => {
        const query = {access_token: token}
        Backend.delete("/video_export/" + exportToBeRemoved?.id, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error)
                } else {
                    console.log("export removed")
                    mutateByRegex(/^\/video_export/)
                }
            });
    }

    let exports = data.video_exports

    const removeExportModal = (
        <ConfirmModal 
            isOpen 
            onClose={() => setExportToBeRemoved(null)} 
            onConfirm={removeExport} 
            confirmText = "Remove"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><FaTrashAlt/></div>
                <div className="confirm-title">Do you really want to remove {exportToBeRemoved?.description} from export?</div>
            </div>
        </ConfirmModal>
    );
    
    return (
        <CustomModal isOpen={isOpen} onRequestClose={onClose} className="wider">
            <div className="exports-cont">
                <div className="exports-modal-title">Exports</div>
                <ExportsTable 
                    exports={exports} 
                    onDownload={onDownload} 
                    downloadIndex={downloadIndex} 
                    setExportToBeRemoved={setExportToBeRemoved}
                    />
                <div className="confirm-cancel-btn-cont center">
                    <button type="button" onClick={onClose}>Close</button>
                    {!mobileDevice && (
                        <div className="exports-table-info">
                            <BsInfoCircle/>
                            <i> This table lists your queued and past exports.
                                You may close and check back later at any point. 
                                <br/> Download link will be valid for a couple of weeks.
                            </i>
                        </div>
                    )}
                </div>
            </div>
            {exportToBeRemoved && removeExportModal}
        </CustomModal>
    )
}

export default Exports