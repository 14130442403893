import React, {useEffect} from "react";
import Config from "../utility/Config";
// import { useTagging } from "../stores/VideoTagging";
import {formatTimecode, tagToIcon, tagToIconShl, hockeyTimeFormat, getDateFormat} from "../utility/Utilities";
import "./css/VideoSearchEntry.css";
import classNames from "classnames";
import {useMediaQuery} from "react-responsive";
import {FaVideoSlash} from "react-icons/fa";
import {MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank} from "react-icons/md";
import { IoCalendarSharp } from "react-icons/io5";
import { MdSmartDisplay } from "react-icons/md";
// import { MdViewList } from "react-icons/md";

export default function VideoSearchEntry ({
    onClick,
    timestamp,
    tabIndex,
    active=false,
    game=null, // Relatively common to be null

    selectedVideos,
    selectAll,
    addVideoToList, 
    removeVideoFromList,

    // If playlist is null, we know isEvent == true. Most events *do* have a playlist though
    playlist=null,

    // Props only relevant for events. They are either all null or none of them are
    isEvent=false,
    tag=null,
    gameTime=null,
    teamLogo=null,
    disableAction=false,
    tagging=false,
    mobileShowAction=false,
}) {
    
    let title, thumb;
    let gameText = null;

    // Actions work on playlists, so events without playlist cannot use this functionality
    disableAction = disableAction || !playlist
    const isVideoChecked = !disableAction && selectedVideos && selectedVideos.has(playlist.id);
    const mobileDevice = useMediaQuery({maxWidth: 768})

    if (playlist) {
        title = playlist.description;
        thumb = <img src={playlist.thumbnail_url} alt="thumbnail" />
    } else {
        // Note: isEvent == true
        title = tag.action; // TODO Better fallback
        thumb = (
            <div className="video-without-video-icon flex-vertically-centered">
                <FaVideoSlash />
            </div>
        );
    }

    if (game) {
        const homeName = game.home_team.short_name || game.home_team.name;
        const visitingName = game.visiting_team.short_name || game.visiting_team.name;
        gameText = `${homeName} v ${visitingName}`;
    }

    // const {tagVideo} = useTagging()

    // const onTagVideo = (e) => {
    //     e.stopPropagation()
    //     tagVideo(game)
    // }

    const addForAction = (e) => {
        e.stopPropagation();
        addVideoToList(playlist);
    }

    const removeFromAction = (e) => {
        e.stopPropagation();
        removeVideoFromList(playlist.id);
    }

    // If disable action is false and select all is true
    useEffect(()=> {
        if (!disableAction && selectAll){
            addVideoToList(playlist);
        }
    }, [disableAction, selectAll]);

    // TODO make a neater check for SHL, Demo, etc
    const isShl = Config.association === "SHL"
    const shlGameTime = isShl && hockeyTimeFormat(tag)
    const eventGameTime = isShl? shlGameTime : gameTime + "'"

    if (mobileDevice) return (
        <li onClick={onClick} className="video-entry-mobile">
            <div className={classNames("video-entry-mobile-checkbox", {"show": mobileShowAction})}>
                {isVideoChecked? (
                    <div onClick={removeFromAction}>
                        <MdOutlineCheckBox className="video-checked-box" />
                    </div>
                ) : (
                    <div onClick={addForAction}>
                        <MdOutlineCheckBoxOutlineBlank className="video-unchecked-box"/>
                    </div> 
                )}
            </div>
            <div className="video-entry-mobile-thumb">
                {thumb}
                {playlist && (
                    <div className="video-duration mobile">{formatTimecode(playlist.duration_ms/1000)}</div>
                )}
            </div>
            <div className="video-entry-mobile-info">
                <div className="video-entry-mobile-title">
                    {title}
                </div>
                <div className="video-entry-mobile-bottom">
                    {isEvent && (
                        <div className="video-entry-mobile-event">
                            <div className="video-entry-mobile-time">
                                {eventGameTime}
                            </div>
                            <div className="video-entry-mobile-logo">
                                {teamLogo}
                            </div>
                        </div>
                    )}
                    <div className="video-entry-mobile-date-match">
                        <div className="video-entry-mobile-date">
                            <IoCalendarSharp className="entry-date-game-icon"/>
                            {getDateFormat(timestamp)}
                        </div>
                        {game && (
                            <div className="video-entry-mobile-date">
                                <MdSmartDisplay className="entry-date-game-icon"/>
                                {gameText}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </li>
    )

    return (
        <li
            className={classNames("video-entry", {
                "active": active,
                "is-event": isEvent,
                "shl": isShl,
            })}
            tabIndex={tabIndex}
            onClick={onClick}
            >
            {!disableAction? (
                <div className="video-checkbox-action">
                    {isVideoChecked? (
                        <div onClick={removeFromAction}>
                            <MdOutlineCheckBox className="video-checked-box" />
                        </div>
                    ) : (
                        <div onClick={addForAction}>
                            <MdOutlineCheckBoxOutlineBlank className="video-unchecked-box"/>
                        </div> 
                    )}
                </div>
            ) : (
                <div className="no-checkbox-action"></div>
            )}
            <div className="video-entry-thumb">
                {thumb}
                {playlist && (
                    <div className="video-duration">{formatTimecode(playlist.duration_ms/1000)}</div>
                )}
            </div>
            {isEvent && (
                <div className="video-entry-logos">
                    {teamLogo}
                </div>
            )}
            <div className="video-entry-top-bottom">
                <div className="video-entry-top">
                    {isEvent && (
                        <div className="game-time">
                            {eventGameTime}
                        </div>
                    )}
                    {isEvent && (
                        <div className="video-entry-icons">
                            {isShl? tagToIconShl(tag?.action) :  tagToIcon(tag.action)}
                        </div>
                    )}
                    <div className="video-entry-info-title">
                        <div className="entry-date-and-game">
                            {game && (
                                <div className="video-entry-game">
                                    <MdSmartDisplay className="entry-date-game-icon"/>
                                    {gameText}
                                </div>
                            )}
                            <div className="video-entry-date">
                                <IoCalendarSharp className="entry-date-game-icon"/>
                                {getDateFormat(timestamp)}
                            </div>
                            {(playlist && playlist.is_private && !isEvent) && (
                                <div className="video-entry-is-unlisted">Not public</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="video-entry-bottom">
                    <div className="video-entry-details">
                        {title}
                    </div>
                </div>
            </div>
            {/* {(tagging && game) && (
                <div onClick={onTagVideo} className="asset-tag-video">
                    Tag video
                </div>
            )} */}
        </li>
    )
}