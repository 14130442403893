import React, {useEffect, useState} from "react"
import VideoSearchEntry from "./VideoSearchEntry"
import {useSWRConfig} from "swr";
import {useNavigate} from "react-router-dom";
import { Paging } from "../utility/Paging";
import { useAddVideoToAction } from "../utility/Utilities";
import LoadingScreen from "./LoadingScreen";
import VideoAction from "./VideoAction";
import classNames from "classnames";
import { useBackend } from "../utility/Backend";
import { useMediaQuery } from "react-responsive";
import { PiListChecksBold } from "react-icons/pi";
import "../components/css/VideoSearchEntry.css"

function SearchResultSingle ({idx, playlist, selectedVideos, addVideoToList, removeVideoFromList, mobileShowAction}) {

    const {game, date} = playlist

    const {cache} = useSWRConfig();
    const navigate = useNavigate();

    const urlPathname = window.location.pathname
    const urlAfterLibrary = urlPathname.substring(urlPathname.indexOf("y") + 1);

    function onClick () {
        cache.set("editing_playlist", playlist);
        // Doing this a little more manually to avoid using a hook in dozens/hundreds of small components
        const q = new URLSearchParams(window.location.search);
        q.set("editing", "playlist_" + playlist.id);
        navigate("/library" + urlAfterLibrary + "?" + q.toString());
        return false;
    }

    return (
        <VideoSearchEntry
            tabIndex={20+idx} 
            onClick={onClick}
            playlist={playlist}
            game={game}
            timestamp={date}
            active={false}
            disableAction={false}
            selectedVideos={selectedVideos}
            selectAll={null}
            addVideoToList={addVideoToList}
            removeVideoFromList={removeVideoFromList}
            mobileShowAction={mobileShowAction}
            />
    )
}

function FreeTextSearchResults ({inputParams, from, to, showingSuggestionsOrInfo=false}) {
    
    const [
        selectedVideos, ,
        addVideoToList, 
        removeVideoFromList, 
        clearSelectedList, ,
    ] = useAddVideoToAction();
    
    const [searchResultsPage, setSearchResultsPage] = useState(1)
    const [mobileShowAction, setMobileShowAction] = useState(false)
    const mobileDevice = useMediaQuery({maxWidth: 768})

    useEffect(() => {
        setSearchResultsPage(1)
    }, [inputParams])

    const resultsPerPage = 10
    const fromPage = searchResultsPage === 1 ? 0 : (searchResultsPage-1) * resultsPerPage

    const query = {
        count: resultsPerPage,
        from: fromPage,
    }

    if (from && to) {
        query["from_date"] = new Date(from).toISOString()
        query["to_date"] = new Date(to).toISOString()
    }

    const {data, error} = useBackend("/freetext" + inputParams, query, {})
    
    if (error) return (
        <div className="no-results-found">
            Nothing found
        </div>
    )

    if (!data) return (
        <div className="loading-search-results">
            <LoadingScreen/>
        </div>
    )

    const playlist = data?.playlists || []
    const totalPage = Math.ceil(data?.total / resultsPerPage)

    const onSearchResultsPageChange = (page) => {
        setSearchResultsPage(page)
    }

    const handleMobileAction = () => {
        if (selectedVideos.length !== 0) clearSelectedList()
        setMobileShowAction(!mobileShowAction)
    }

    const resultsOptionsMobile = mobileDevice && (
        <div className="results-options-mobile">
            <div onClick={handleMobileAction}>
                <PiListChecksBold/>
                {mobileShowAction ? "Cancel selection"  : "Select videos"}
            </div>
        </div>
    )

    if (playlist.length === 0) return (
        <div className="no-results-found">
            Nothing found
        </div>
    )

    return (
        <div className={classNames("search-results-cont", {"filter": showingSuggestionsOrInfo})}>
            <div className="search-results-number">{data.total} Results found</div>
            {resultsOptionsMobile}
            <VideoAction 
                selectedVideos={selectedVideos}
                clearSelectedList={clearSelectedList}/>
            <ul className="search-results-list scrollable">
                {playlist.map((playlist, idx) => {
                    return (
                        <SearchResultSingle 
                            key={playlist.id}
                            idx={idx}
                            playlist={playlist}
                            selectedVideos={selectedVideos}
                            addVideoToList={addVideoToList}
                            removeVideoFromList={removeVideoFromList}
                            mobileShowAction={mobileShowAction}/>
                    )
                })}
            </ul>
            <Paging page={searchResultsPage} pageCount={totalPage} onChange={onSearchResultsPageChange}/>
        </div>
    )
}

export default FreeTextSearchResults