// More leagues might have graphs later. SEF: {}, NTF: {}, ...
export const graphs = {
    SEF: {
        viewCountNumber: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 12,
        },
        viewCountTable: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 16,
        },
        viewCountGraph: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 1,
        },
        durationNumber: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 13,
        },
        durationGraph: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 2,
        },
        durationTable: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 11,
        },
        channelViewNumber: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 14,
        },
        channelDurationNumber: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 15,
        },
        channelViewGraph: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 9,
        },
        channelDurationGraph: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 4,
        },
        channelViewTable: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 18,
        },
        channelDurationTable: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 17,
        },
        deviceUsageGraph: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            panelId: 10,
        },
        videoViewGraph: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            channelId: 184,
            panelId: 5,
        },
        videoDurationGraph: {
            url: "https://stats.forzify.com/d-solo/b3fa7f92-0d88-4bb1-9e06-340c10e8eb38/sef",
            orgId: 1,
            channelId: 184,
            panelId: 3,
        },
        site: ["fotbollplay", "allsvenskan", "superettan"],
    },

    NTF: {
        viewCountNumber: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 12,
        },
        viewCountTable: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 16,
        },
        viewCountGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 1,
        },
        durationNumber: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 13,
        },
        durationGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 2,
        },
        durationTable: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 11,
        },
        channelViewNumber: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 14,
        },
        channelDurationNumber: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 15,
        },
        channelViewGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 9,
        },
        channelDurationGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 4,
        },
        channelViewTable: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 18,
        },
        channelDurationTable: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 17,
        },
        deviceUsageGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            panelId: 10,
        },
        videoViewGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            channelId: 184,
            panelId: 5,
        },
        videoDurationGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9345/ntf",
            orgId: 1,
            channelId: 184,
            panelId: 3,
        },
        site: ["eliteserien", "obosligaen", "brann"],
    },

    toppserien: {
        viewCountNumber: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 12,
        },
        viewCountTable: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 16,
        },
        viewCountGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 1,
        },
        durationNumber: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 13,
        },
        durationGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 2,
        },
        durationTable: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 11,
        },
        channelViewNumber: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 14,
        },
        channelDurationNumber: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 15,
        },
        channelViewGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 9,
        },
        channelDurationGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 4,
        },
        channelViewTable: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 18,
        },
        channelDurationTable: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 17,
        },
        deviceUsageGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            panelId: 10,
        },
        videoViewGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            channelId: 184,
            panelId: 5,
        },
        videoDurationGraph: {
            url: "https://stats.forzify.com/d-solo/f3566f50-a547-41c3-9d22-9b137e4f9348/toppserien",
            orgId: 1,
            channelId: 184,
            panelId: 3,
        }
    }
}

export const graphSiteDictionary = {
    allsvenskan: 1, 
    fotbollplay: 2,
    eliteserien: 4,
    obosligaen: 6,
    superettan: 7,
    brann: 8,
}