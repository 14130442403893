import React, {useState, useEffect, useReducer, useRef} from "react"
import { useBackend, infrequentCacheConf } from "../../utility/Backend";
import { useTagging } from "../../stores/VideoTagging"
import { useCheckIsAdmin } from "../../utility/UserGroups";
import { DualPage } from "../../components/Page";
import Page from "../../components/Page";
import { Helmet } from "react-helmet-async";
import ThumbnailSelector from "../../components/ThumbnailSelector";
import { capitalizeFirstLetter, getDisplayDuration } from "../../utility/Utilities";
import { useMediaQuery } from "react-responsive";
import { ClickOutside } from "../../utility/Utilities";
import { useFeedbackMessage } from "../../stores/FeedbackMessage";
import classNames from "classnames";
import "./Tagging.css"
import {FiCheckSquare, FiSquare} from "react-icons/fi";
import {BsInfoCircle} from "react-icons/bs";
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";

const paramTypeDict = {
    "offending player": "offending_player",
    "player awarded": "player_awarded",
    "assist by": "assist_by",
    "shot type": "shot_type",
    "shot result": "shot_result",
    "on target": "on_target",
    "after set piece": "after_set_piece",
    "player in": "player_in",
    "player out": "player_out",
}

const defaultForm = {
    is_private: true,
    thumbnail_url: null,
    description: "",
    tag: null,
    // tag params
    time: null,
    team: null,
    player: null,
    phase: null,
    offending_player: null,
    player_awarded: null,
    scorer: null,
    assist_by: null,
    shot_type: null,
    shot_result: null,
    keeper: null,
    on_target: null,
    after_set_piece: null,
    reason: null,
    player_in: null,
    player_out: null,
}

function formReducer (state, action) {
    switch (action.type) {
        case "reset": return defaultForm
        case "clear params": return {
            ...state,
            team: null,
            player: null,
            phase: null,
            offending_player: null,
            player_awarded: null,
            scorer: null,
            assist_by: null,
            shot_type: null,
            shot_result: null,
            keeper: null,
            on_target: null,
            after_set_piece: null,
            reason: null,
            player_in: null,
            player_out: null,
        }
        case "clear players": return {
            ...state,
            player: null,
            offending_player: null,
            player_awarded: null,
            scorer: null,
            assist_by: null,
            keeper: null,
            player_in: null,
            player_out: null,
        }
        default: {
            if (state.hasOwnProperty(action.type))
                return {...state, [action.type]: action.payload}
            return state
        }
    }
}

function TaggingForm ({game, onCreateTag, onClear}) {
    
    const titleRef = useRef(null)
    const taggingParamRef = useRef(null)
    const taggingTimeRef = useRef(null)
    const [taggingState, taggingDispatch] = useReducer(formReducer, defaultForm);
    
    const [editingParamIndex, setEditingParamIndex] = useState(null)
    const [timePassed, setTimePassed] = useState(null)
    const [thumbnailImage, setThumbnailImage] = useState(null)

    const {data: gamePlayers} = useBackend(game? ("/game/" + game.id + "/players") : null, {}, infrequentCacheConf)
    const {data: tagsData} = useBackend("/tag", undefined, {revalidateIfStale: false, revalidateOnFocus: false});
    
    const homeTeamPlayers = gamePlayers?.home_team.players || []
    const awayTeamPlayers = gamePlayers?.visiting_team.players || []

    const tags = tagsData?.tags || []
    const tagParam = tags[taggingState.tag] || []
    const tagsTypes = tagsData?.types || []

    const mobileDevice = useMediaQuery({maxWidth: 768})
    const [,isAdmin] = useCheckIsAdmin()
    // const isAdmin = false

    useEffect(() => {
        taggingDispatch({type: "clear params"})
    }, [taggingState.tag])

    useEffect(() => {
        taggingDispatch({type: "clear players"})
    }, [taggingState.team])

    useEffect(() => {
        const timePassed = new Date().getTime() - new Date(taggingState.time).getTime()
        setTimePassed(timePassed)
        const countTime = setInterval(() => {
            const timePassed = new Date().getTime() - new Date(taggingState.time).getTime()
            setTimePassed(timePassed)
        }, 1000)
        return () => clearInterval(countTime)
    }, [taggingState.time])

    useEffect(() => {
        if (taggingTimeRef.current && mobileDevice) taggingTimeRef.current.scrollIntoView({ behavior: "smooth" })
    }, [taggingState.tag])
    
    ClickOutside(taggingParamRef, setEditingParamIndex, true)

    if (!game) return null

    const validTags = isAdmin ? Object.keys(tags).map(t => t) : ["fan tagging"]
    const editingParam = tagParam[editingParamIndex]
    
    const onDispatch = (name, payload) => {
        taggingDispatch({type: name, payload: payload})
    }

    const onDispatchParam = (payload) => {

        const paramName = paramTypeDict[editingParam.name] || editingParam.name

        // remove if selecting the same selected param value
        if (taggingState[paramName] === payload) {
            taggingDispatch({type: paramName, payload: ""})
        } else {
            taggingDispatch({type: paramName, payload: payload})
        }

        if (mobileDevice) {
            setEditingParamIndex(null)
        } else {
            // auto jump to next param selection after selecting a param value
            if (tagParam.length-1 > editingParamIndex) {
                setEditingParamIndex(editingParamIndex + 1)
            } else {
                setEditingParamIndex(null)
            }
        }
    }

    const onSelectTag = (tag) => {
        if (taggingState.tag === tag) return
        onDispatch("tag", tag)
        onDispatch("time", new Date())
    }

    const onSelectEditingParam = (idx) => {
        if (editingParamIndex === idx) setEditingParamIndex(null)
        else setEditingParamIndex(idx)
    }

    const onAdjustTaggedTime = (sec) => {
        const currentSecond = new Date(taggingState.time).getSeconds()
        const updatedTime = new Date(taggingState.time).setSeconds(currentSecond + sec)
        onDispatch("time", updatedTime)
    }

    const createTag = () => {
        onCreateTag()
        taggingDispatch({type: "reset"})
    }
    
    const homeTeam = game.home_team
    const awayTeam = game.visiting_team
    const teams = [homeTeam, awayTeam]

    const playersList = () => {

        if (!taggingState.team) return []
        
        const homeTeamSelected = taggingState.team.id === homeTeam.id
        const awayTeamSelected = taggingState.team.id === awayTeam.id
        
        if (homeTeamSelected) {
            if (["offending player", "keeper"].includes(editingParam.name)) {
                return awayTeamPlayers
            } else {
                return homeTeamPlayers
            }
        }

        if (awayTeamSelected) {
            if (["offending player", "keeper"].includes(editingParam.name)) {
                return homeTeamPlayers
            } else {
                return awayTeamPlayers
            }
        }
        else return []
    }
    
    // const ignoredTags = ["end of game", "start phase", "end phase", "throw-in", "highlights"];

    const playersInTaggingState = Object.keys(taggingState).reduce((list, key) => {
        if (taggingState[key]?.name) list.push({param: key, id: taggingState[key].id})
        return list
    }, [])

    const optionsInTaggingState = Object.keys(taggingState).reduce((list, key) => {
        if (typeof taggingState[key] === "string") list.push({param: key, value: taggingState[key]})
        return list
    }, [])

    // TODO move to a new component
    function renderTagParamOptions () {
        
        if (!editingParam) return

        if (editingParam.type === "none") return

        if (editingParam.type === "team") return (
            <div className="tagging-param-options team">
                {teams.map((team) => {
                    return (
                        <div 
                            key={team.id} 
                            onClick={() => onDispatchParam(team)}
                            className={classNames("param-option-single", {"active": taggingState.team?.id === team.id})}
                            >
                            <img src={team.logo_url} className="param-option-team-logo" alt="team logo" />
                            <div className="param-option-team-name">{team.name}</div>
                        </div>
                    )
                })}
            </div>
        )

        if (editingParam.type === "player") {
            const playerToList = playersList()

            if (playerToList.length === 0) return (
                <div className="tagging-param-options">
                    <div className="select-team-show-players">Select team to show players</div>
                </div>
            )

            return (
                <div className="tagging-param-options">
                    {playersList().map((player) => {
                        const playerInTaggingState = playersInTaggingState.find((p) => p.id === player.id)
                        const paramName = paramTypeDict[editingParam.name] || editingParam.name
                        const playerSelectedInList = playerInTaggingState?.param === paramName
                        return (
                            <div 
                                key={player.id} 
                                onClick={() => onDispatchParam(player)}
                                className={classNames("param-option-single", {"active": playerSelectedInList})}
                                >
                                <div className="param-option-player-number">{player.shirt_number}</div>
                                <div className="param-option-player-name">{player.name}</div>
                                {playerInTaggingState && (
                                    <div className="param-option-player-selected">{capitalizeFirstLetter(playerInTaggingState.param).replace("_", " ")}</div>
                                )}
                            </div>
                        )
                    })}
                </div>
            )
        }

        const tagTypeOptions = tagsTypes[editingParam.type].options

        if (tagTypeOptions.length !== 0) return (
            <div className="tagging-param-options">
                {tagTypeOptions.map((option) => {
                    const optionInTaggingState = optionsInTaggingState.find((o) => o.value === option)
                    const paramName = paramTypeDict[editingParam.name] || editingParam.name
                    const optionSelectedInList = optionInTaggingState?.param === paramName
                    return (
                        <div 
                            key={option} 
                            onClick={() => onDispatchParam(option)}
                            className={classNames("param-option-single", {"active": optionSelectedInList})}
                            >
                            {capitalizeFirstLetter(option)}
                        </div>
                    )
                })}
            </div>
        )
        else return
    }

    const setThumbnail = (newUrl) => {
        if (typeof newUrl === "object") {
            setThumbnailImage(newUrl.imageFile)
            onDispatch("thumbnail_url", newUrl.imageUrl)
        }
        else onDispatch("thumbnail_url", newUrl)
    }

    const tagsOptions = (
        <div>
            <div className="tagging-section-title">
                Tags
            </div>
            <div className="tagging-tags-options">
                {validTags.map((t) => {
                    return (
                        <div 
                            key={t}
                            onClick={() => onSelectTag(t)}
                            className={classNames("tag-single", {
                                "active": taggingState.tag === t,
                                "fan-tagging": t === "fan tagging"
                            })}
                            >
                            {capitalizeFirstLetter(t)}
                        </div>
                    )
                })}
            </div>
        </div>
    )

    return (
        <div className="tagging-form-cont">
            <form className="tagging-form" onSubmit={(e) => e.preventDefault()}>
                {tagsOptions}
                {taggingState.tag && (
                    <div ref={taggingTimeRef} className="">
                        <div className="tagging-section-title">
                            Tagging time
                        </div>
                        <div className="tagging-tagged-at">
                            <div className="tagged-time-title">Tagged at</div>
                            <div className="tagged-time">{new Date(taggingState.time).toLocaleTimeString()}</div>
                            <div className="tagged-time-passed">{getDisplayDuration(timePassed)} ago</div>
                        </div>
                        <div className="tagging-time-adjustment">
                            <div className="tagged-time-title">Adjust time</div>
                            <button onClick={() => onAdjustTaggedTime(-20)} className="tagging-time-adjust">- 20 sec</button>
                            <button onClick={() => onAdjustTaggedTime(-5)} className="tagging-time-adjust">- 5 sec</button>
                            <button onClick={() => onAdjustTaggedTime(5)} disabled={timePassed <= 5000} className="tagging-time-adjust">+ 5 sec</button>
                            <button onClick={() => onAdjustTaggedTime(20)} disabled={timePassed <= 20000} className="tagging-time-adjust">+ 20 sec</button>
                        </div>
                    </div>
                )}
                {!taggingState.tag && (
                    <div className="select-tag-open-form">Select tag to start tagging</div>
                )}
                {tagParam.length !==0 && (
                    <div className="tagging-params-cont">
                        <div className="tagging-section-title">Tag details</div>
                        <div ref={taggingParamRef} className="tagging-params-and-options">
                            <div className="tagging-tag-param">

                                {tagParam.map((p, idx) => {

                                    let paramValue

                                    if (p.type === "team") {
                                        paramValue = taggingState[p.name]?.name
                                    } 
                                    else if (p.type === "player") {
                                        const tagParamName = taggingState[paramTypeDict[p.name]] || taggingState[p.name] || {}
                                        const shirtNumber = tagParamName.shirt_number
                                        const playerName = tagParamName.name
                                        if (shirtNumber && playerName) {
                                            paramValue = `${shirtNumber}, ${playerName}`
                                        }
                                    }  
                                    else {
                                        paramValue = taggingState[paramTypeDict[p.name]] || taggingState[p.name]
                                    }
                                    
                                    return (
                                        <div 
                                            key={p.name} 
                                            onClick={() => onSelectEditingParam(idx)}
                                            className={classNames("tag-param-single", {"active": editingParam?.name === p.name})}
                                            >
                                            <div className="tag-param-single-title">{capitalizeFirstLetter(p.name)}</div>
                                            <div className="tag-param-single-value">{paramValue}</div>
                                            <IoMdArrowDropright/>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={classNames("tagging-param-options-cont", {"show": editingParam})}>
                                {(mobileDevice && editingParam) && (
                                    <div className="param-options-mobile-header">
                                        <div className="param-options-mobile-header-title">{editingParam.name}</div>
                                        <div onClick={() => setEditingParamIndex(null)} className="param-options-mobile-header-back">
                                            <IoMdArrowRoundBack/>
                                            Back
                                        </div>
                                    </div>
                                )}
                                {renderTagParamOptions()}
                            </div>
                        </div>
                    </div>
                )}
                {taggingState.tag && (
                    <>
                        <div ref={titleRef} className="input-container">
                            <label className="input-title">Title</label>
                            <input
                                type="text"
                                value={taggingState.description}
                                onChange={(e) => onDispatch("description", e.target.value)}>
                            </input>
                        </div>
                        {isAdmin && (
                            <div className="input-container">
                                <label className="input-title">Published</label>
                                <>
                                    <div
                                        onClick={() => onDispatch("is_private", true)}
                                        className={classNames("option-checkbox", {"active": taggingState.is_private})}>
                                        {taggingState.is_private?
                                            <FiCheckSquare className="check-box-icon"/> :
                                            <FiSquare className="check-box-icon"/>}
                                        <div className="option-box-title">
                                            Not yet published
                                            <div className="option-extra-message">
                                                <BsInfoCircle className="info-icon"/> video will be unlisted (can be changed later)
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => onDispatch("is_private", false)}
                                        className={classNames("option-checkbox", {"active": !taggingState.is_private})}>
                                        {!taggingState.is_private?
                                            <FiCheckSquare className="check-box-icon"/> :
                                            <FiSquare className="check-box-icon"/>}
                                        <div className="option-box-title">
                                            Published
                                        </div>
                                    </div>
                                </>
                            </div>
                        )}
                        {/* <div className="input-container">
                            <label className="input-title">Thumbnail</label>
                            <ThumbnailSelector
                                selected={formState.thumbnail_url}
                                onChange={setThumbnail}
                                playlistClips={playlistClips}
                                />
                        </div> */}
                    </>
                )}
            </form>
            <div className="confirm-cancel-btn-cont between margin-top">
                <button onClick={createTag} disabled={!taggingState.tag} className="green-btn">
                    Create tag
                </button>
                <button onClick={() => window.history.back()}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

function Tagging () {

    const {gameForTagging} = useTagging()
    const [,isAdmin] = useCheckIsAdmin()
    const {showFeedback,} = useFeedbackMessage();

    const [time, setTime] = useState(new Date())

    useEffect(() => {
        const liveTime = setInterval(() => {
            setTime(new Date())
        }, 1000)
        return () => clearInterval(liveTime)
    }, [])

    if (!gameForTagging) return (
        <Page>
            <div className="empty-tagging-page">Go to match page and select a match to start tagging</div>
        </Page>
    )

    const onCreateTag = () => {
        showFeedback("success", "Tag created successfully")
    }

    const onClear = () => {
        return
    }

    const hours = time.getHours()
    const minutes = time.getMinutes()
    const seconds = time.getSeconds()

    const taggingClockTime = (
        <div className="tagging-live-time-cont">
            <div className="tagging-live-time">{hours <= 9 ? `0${hours}`: hours}</div>
            <div>:</div>
            <div className="tagging-live-time">{minutes <= 9 ? `0${minutes}`: minutes}</div>
            <div>:</div>
            <div className="tagging-live-time">{seconds <= 9 ? `0${seconds}`: seconds}</div>
        </div>
    )

    const taggingPageTitle = `${isAdmin ? "" : "Fan "}Tagging - ${gameForTagging.home_team.name} vs ${gameForTagging.visiting_team.name}`

    return (
        <DualPage withLayoutSelection defaultLayout={0}>
            <Page title={taggingPageTitle}>
                <Helmet>
                    <title>Tagging</title>
                </Helmet>
                <div className="tagging-cont">
                    {taggingClockTime}
                    <TaggingForm game={gameForTagging} onCreateTag={onCreateTag} onClear={onClear}/>
                </div>
            </Page>
            <Page title="">
            </Page>
        </DualPage>
    )
}

export default Tagging