import React, {useState} from "react";
import Config from "../../../utility/Config";
import Dropdown from "../../../components/Dropdown";
import {duplicateSearchParamsSubset, useUpdateSearchParams} from "../../../utility/Utilities";
import {getFullPath, infrequentCacheConf, useBackend} from "../../../utility/Backend";
import SearchResults from "../../../components/SearchResults";
import FullscreenFilter from "../../../components/FullscreenFilter";
import ConfirmModal from "../../../components/ConfirmModal";
import {Link} from "react-router-dom";
import {useSWRConfig} from "swr";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import "./MatchListing.css";
import { IoMdOptions } from "react-icons/io";
import { MdFindInPage } from "react-icons/md";

function createDateFilter (query, searchParams, updateSearchParams, showUpcoming) {

    const seasons = Config.seasons;
    let season = searchParams.get("season") || "all";
    if (season !== "all") {
        // Sanitize input to only allow pre-selected options
        if (!seasons.includes(season)) season = seasons[0];
        let [start, end] = Config.seasonInterval(season)
        if (!showUpcoming) {
            const today = new Date();
            if (today < end) end = today;
        }
        query["from_date"] = start.toISOString().split("T")[0];
        query["to_date"] = end.toISOString().split("T")[0];
    } else if (showUpcoming) {
        query["from_date"] = new Date().toISOString().split("T")[0];
    } else if (!showUpcoming) {
        query["to_date"] = new Date().toISOString().split("T")[0];
    }

    return [query, (
        <div>
            <Dropdown
                centered
                title="Season"
                special="all"
                fallback="all"
                selected={season}
                options={seasons}
                onChange={v => updateSearchParams("season", v)}/>
        </div>
    )];
}

function createRoundFilter (query, searchParams, updateSearchParams) {
    let round =  parseInt(searchParams.get("round"), 10) || null;
    // Make an array with [1,2,3...] to roundsPerSeason
    const options = Array(Config.roundsPerSeason).fill(0).map((e, i) => i+1);
    if (round && !options.includes(round)) round = null;

    if (round) query["round"] = round;

    return [query, (
        <div>
            <Dropdown
                title="Round"
                special="all"
                fallback="all"
                centered
                selected={round}
                options={options}
                onChange={v => updateSearchParams("round", v)}/>
        </div>
    )];
}

function createTeamFilter (query, team, teams, updateSearchParams) {
    if (team) query["team_id"] = team;
    if (teams === null) {
        return [query, null];
    }

    let options = Object.keys(teams);
    options.sort((a,b) => (teams[a].name > teams[b].name) ? 1 : -1);

    return [query, (
        <div>
            <Dropdown
                title="Team"
                special="all"
                fallback="all"
                selected={team}
                options={options}
                render={(id) => teams[id].name}
                btnRender={(id) => teams[id].short_name || teams[id].name}
                onChange={v => updateSearchParams({"team": v})}/>
        </div>
    )];
}

export default function MatchListing () {
    
    const [searchParams, updateSearchParams,] = useUpdateSearchParams();

    const [weeklyHighlights, setWeeklyHighlights] = useState(false)

    const showUpcoming = searchParams.get("show") !== null;
    let team = parseInt(searchParams.get("team"), 10) || null;
    let apiQuery = {};
    let dateFilter, teamFilter, roundFilter, periodFilter;

    if (showUpcoming) apiQuery.asc = true;

    [apiQuery, dateFilter] = createDateFilter(apiQuery, searchParams, updateSearchParams, showUpcoming);
    [apiQuery, roundFilter] = createRoundFilter(apiQuery, searchParams, updateSearchParams);
    
    let teams = null;
    const {data: activeTeams} = useBackend(
        "/team/active_teams",
        apiQuery,
        infrequentCacheConf);
    if (activeTeams) {
        teams = activeTeams["teams"].reduce((agg, t) => {agg[t.id] = t; return agg;}, {});
        if (team && !teams[team]) team = null;
    }

    const [mobileShowFilters, setMobileShowFilters] = useState(false)

    periodFilter = (
        <div className="match-period-filter-cont">
            <div
                onClick={() => updateSearchParams("show", null)}
                className={classNames("match-period-filter", {"active": !showUpcoming})}>
                Finished
            </div>
            <div
                onClick={() => updateSearchParams("show", "upcoming")}
                className={classNames("match-period-filter", {"active": showUpcoming})}>
                Upcoming
            </div>
        </div>
    );

    [apiQuery, teamFilter] = createTeamFilter(apiQuery, team, teams, updateSearchParams);

    let apiPath;
    // If we're missing data, dont perform the request
    if (teams === null) apiPath = null;
    else apiPath = getFullPath("/game", apiQuery);

    const {cache} = useSWRConfig();
    const currentSearch = duplicateSearchParamsSubset(["editing"], searchParams);

    function navigateToMatch (match) {
        cache.set("viewed_match", match);
    }

    let previousDate = null

    const renderItem = (games) => {

        const gamesList = games.map((g, idx) => {
            
            const showDate = g.date !== previousDate
            previousDate = g.date

            // TODO fulltime break is used in NLD
            const showScore = g.phase === "finished" || g.phase === "fulltime break" || g.has_live_playlist;
            const url = `/library/match/${g.id}${currentSearch}`

            const isShl = Config.association === "SHL"
            const gameStartTime = moment(g.start_time).format("HH:mm")
            const gameDate = new Date(g.date)
            const todayYear = new Date().getFullYear()
            const thisYear = todayYear === gameDate.getFullYear();
            const gameListDate = thisYear ? moment(g.date).format("MMMM DD") : moment(g.date).format("LL")

            return (
                <div key={g.id}>
                    {showDate && <div className="match-date">{gameListDate}</div>}
                    <Link
                        key={g.id}
                        to={url}
                        tabIndex={20+idx}
                        onClick={() => navigateToMatch(g)}
                        className={classNames("match-single", {"shl-match": isShl})}
                        >
                        <div className="match-time-and-live">
                            <div className="match-start-time">{gameStartTime}</div>
                            {g.has_live_playlist && <div className="live-banner">LIVE</div>}
                            {(g.phase !== "finished" && !g.has_live_playlist) && <div className="match-upcoming">Upcoming</div>}
                        </div>
                        <div className="match-scoreboard">
                            <div className="match-home">
                                <div className="match-club-name">{g.home_team.name}</div>
                                <div className="match-club-logo">
                                    <img src={g.home_team.logo_url} alt="logo"/>
                                </div>
                            </div>
                            {showScore? (
                                <div className="match-score">{g.home_team_goals}-{g.visiting_team_goals}</div>
                                ) : (
                                    <div className="match-score-vs">vs</div>
                            )}
                            <div className="match-visiting">
                                <div className="match-club-logo">
                                    <img src={g.visiting_team.logo_url} alt="logo"/>
                                </div>
                                <div className="match-club-name">{g.visiting_team.name}</div>
                            </div>
                        </div>
                    </Link>
                </div>
            )
        })
        
        return gamesList
    }

    const mobileDevice = useMediaQuery({maxWidth: 768})

    const mobileVideoFilters = mobileDevice && (
        <FullscreenFilter showFilter={mobileShowFilters} onSetFilter={setMobileShowFilters}>
            <>
                {dateFilter}
                {teamFilter}
                {roundFilter}
            </>
        </FullscreenFilter>
    )

    const resultsOptionsMobile = mobileDevice && (
        <div className="match-options-mobile">
            <div className="match-period-mobile">
                <div
                    onClick={() => updateSearchParams("show", null)}
                    className={classNames("match-period-filter mobile", {"active": !showUpcoming})}>
                    Finished
                </div>
                <div
                    onClick={() => updateSearchParams("show", "upcoming")}
                    className={classNames("match-period-filter mobile", {"active": showUpcoming})}>
                    Upcoming
                </div>
            </div>
            <div className="match-filters-mobile" onClick={() => setMobileShowFilters(true)}><IoMdOptions/> Filters</div>
        </div>
    )

    const highlightsNotAvailableModal = (
        <ConfirmModal 
            isOpen
            onClose={() => setWeeklyHighlights(false)}
            cancelText="Close"
            noConfirmBtn
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><MdFindInPage/></div>
                <div className="confirm-title">No games found from last week</div>
            </div>
        </ConfirmModal>
    );

    return (
        <div className="event-listing">
            {/* Only EL for now */}
            {Config.association === "EL" && (
                <button 
                    onClick={() => setWeeklyHighlights(!weeklyHighlights)}
                    className="weekly-highlights green-hover-btn">
                    Generate weekly highlights
                </button>
            )}
            <div className="event-filters">
                {dateFilter}
                {teamFilter}
                {roundFilter}
                {periodFilter}
            </div>
            {mobileVideoFilters}
            {resultsOptionsMobile}
            <SearchResults key={apiPath}
                           path={apiPath}
                           render={renderItem}
                           renderMatch
                           disableAction/>
            {weeklyHighlights && highlightsNotAvailableModal}
        </div>
    );
};
