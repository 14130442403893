import React, {useState, createContext, useContext} from "react"
import { useNavigate } from "react-router-dom";

const TaggingContext = createContext(null);

export function TaggingProvider ({children}) {

    const navigate = useNavigate()

    const [gameForTagging, setGameForTagging] = useState(null)

    const tagVideo = (game) => {
        setGameForTagging(game)
        navigate("/tagging")
    }

    const context = {
        gameForTagging,
        tagVideo,
    }

    return (
        <TaggingContext.Provider value={context}>
            {children}
        </TaggingContext.Provider>
    )
}

export function useTagging () {
    const context = useContext(TaggingContext);
    if (context === undefined)
        throw new Error("useTagging used outside of its provider");
    return context;
}