import React, {useState} from "react"
import Backend from "../../../utility/Backend";
import { useLoginSession } from "../../../stores/loginSession";
import { capitalizeFirstLetter, useUpdateSearchParams } from "../../../utility/Utilities";
import { useMutateByRegex } from "../../../utility/Utilities";
import { useFeedbackMessage } from "../../../stores/FeedbackMessage";
import { useCheckIsAdmin } from "../../../utility/UserGroups";
import ConfirmModal from "../../../components/ConfirmModal";
import {FaTrashAlt} from "react-icons/fa";
import {BiDuplicate} from "react-icons/bi";
import "./GraphicPackage.css";
import "../../../DataInfoUtility.css";

function PackageDetails ({singlePackage, duplicatePackage}) {
    
    const {token} = useLoginSession();
    const mutateByRegex = useMutateByRegex()
    const {showFeedback,} = useFeedbackMessage();
    const [,, resetAllSearchParamsExcept] = useUpdateSearchParams();
    const [isFsAdmin,] = useCheckIsAdmin()

    const [isDeletePackageOpen, setIsDeletePackageOpen] = useState(false);

    if (!singlePackage) return null

    const {params, template} = singlePackage

    const deletePackage = () => {
        const query = {access_token: token}

        Backend.delete("/graphics/package/" + singlePackage.id, query)
            .then(({error}) => {
                if (error) {
                    console.error("Failed to DELETE", error);
                    showFeedback("warning", "Failed to delete package, " + error);
                } else {
                    console.log("Stream deleted");
                    resetAllSearchParamsExcept([])
                    mutateByRegex(/^\/|(graphics)/)
                    showFeedback("success", "Graphic package deleted successfully!");
                }
            });
        return false;
    }

    const deleteStreamModal = (
        <ConfirmModal 
            isOpen 
            onClose={() => setIsDeletePackageOpen(false)} 
            onConfirm={deletePackage} 
            confirmText = "Delete"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><FaTrashAlt/></div>
                <div className="confirm-title">Do you really want to delete {singlePackage.name} graphic package?</div>
            </div>
        </ConfirmModal>
    )

    const graphicPackageDetails = template.template.map((p) => {

        let value = params[p.property] || p.default || null
        let valueToRender = value

        if (!valueToRender) valueToRender = "-"

        if (p.type === "text") valueToRender = capitalizeFirstLetter(value)

        if (p.type === "boolean") valueToRender = value ? "Yes" : "No"

        if (p.type === "color") valueToRender = (
            <div className="package-color-value">
                <div style={{background: valueToRender}} className="package-color-box"></div>
                <div className="package-color-hex">{valueToRender}</div>
            </div>
        )

        if (p.multioption && Array.isArray(value)) valueToRender = (
            <div className="params-included-events-cont">
                {value.map((e, idx) => {
                    return (
                        <div key={e} className="params-value float-right">
                            {capitalizeFirstLetter(e)}
                            {value.length-1 !== idx && ","}
                        </div>
                    )
                })}
            </div>
        )

        return (
            <div key={p.title} className="data-info">
                <div className="data-info-title">{p.title}</div>
                <div className="data-info-value">{valueToRender}</div>
            </div>
        )
    })

    return (
        <div className="graphic-package-details-cont">
            <div className="data-info-cont">
                <div className="data-info-head-title">Graphic package details</div>
                {graphicPackageDetails}
                <div className="confirm-cancel-btn-cont between margin-top">
                    <button onClick={() => duplicatePackage(singlePackage)}>
                        <BiDuplicate className="icon-in-btn"/>
                        Duplicate package
                    </button>
                    {isFsAdmin && (
                        <button onClick={() => setIsDeletePackageOpen(true)} className="red-btn">
                            <FaTrashAlt className="icon-in-btn"/>
                            Delete graphic package
                        </button>
                    )}
                </div>
            </div>
            {isDeletePackageOpen && deleteStreamModal}
        </div>
    )
}

export default PackageDetails