import React, {useEffect, useState, useMemo} from "react"
import Backend from "../../../utility/Backend";
import SmallDropdown from "../../../components/SmallDropdown";
import { useLoginSession } from "../../../stores/loginSession";
import { useFeedbackMessage } from "../../../stores/FeedbackMessage";
import { showErrorMessage } from "../../../utility/Utilities";
import "./GraphicPackage.css"
import { BooleanField, DropdownListField, TextField, MultipleSelectField, ColorSelectionField } from "./GraphicPackageInput";

function GraphicPackageForm ({templates, packageForDuplicate=null, mutate, onCancel}) {

    const {token} = useLoginSession();
    const {showFeedback} = useFeedbackMessage();

    const [packageName, setPackageName] = useState("")
    const [hasInputError, setHasInputError] = useState(false)
    
    // TODO use forzasys as a temporary default graphic template
    const [selectedTemplate, setSelectedTemplate] = useState("ForzaSys")
    const templatesNames = templates.map(t => t.name)
    const mainTemplate = templates.find((t) => t.name === selectedTemplate)
    
    const baseTemplate = mainTemplate?.template || []

    const packageParams = useMemo(() => baseTemplate.reduce((form, schema) => {
        form[schema.property] = schema.default
        return form
    }, {}), 
    [mainTemplate, selectedTemplate])

    const [packageParamsData, setPackageParamsData] = useState(packageParams)
    
    const packageNameInvalid = packageName.length < 5
    const emptyEventList = packageParamsData.included_events.length === 0

    useEffect(() => {
        if (packageParams && !packageForDuplicate) setPackageParamsData(packageParams)
    }, [packageParams, packageForDuplicate])

    useEffect(() => {
        if (packageForDuplicate) {
            setSelectedTemplate(packageForDuplicate.template.name)
            setPackageName(packageForDuplicate.name + " - duplicate")
            setPackageParamsData(packageForDuplicate.params)
        }
    }, [packageForDuplicate])

    useEffect(() => {
        if (hasInputError && !packageNameInvalid && !emptyEventList) setHasInputError(false)
    }, [packageNameInvalid, emptyEventList])

    if (Object.keys(packageParamsData).length === 0) return null

    const resetCreatePackage = () => {
        setPackageName("")
        setHasInputError(false)
        onCancel()
    }

    const onCreatePackage = async () => {

        if (packageNameInvalid) {
            setHasInputError (true)
            return
        }

        if (packageParamsData.included_events.length === 0) {
            setHasInputError (true)
            return
        }

        const packageData = {
            name: packageName,
            graphics_template_id: mainTemplate.id.toString(),
            params: packageParamsData
        }

        console.log("create graphic package", packageData);

        const {error} = await Backend.post("/graphics/package", JSON.stringify(packageData), {access_token: token})

        if (error) {
            console.error("Failed to POST", error);
            showFeedback("warning", "Failed to create graphic package, " + error);
        } else {
            resetCreatePackage()
            mutate()
            console.log("New graphic package created");
            showFeedback("success", "Graphic package created successfully!");
        }
    }

    const packageParamsInput = baseTemplate.map((schema) => {
        const type = schema.type
        const property = schema.property
        const value = packageParamsData[property]
        const onChange = (v) => {
            setPackageParamsData({...packageParamsData, [property]: v})
        }

        switch (type) {
            case "text": {
                if (schema.options) {
                    return <DropdownListField
                        key={property}
                        schema={schema}
                        value={value}
                        onChange={onChange}
                    />
                }
                else {
                    return <TextField
                        key={property}
                        schema={schema}
                        value={value}
                        onChange={onChange}
                        hasInputError={hasInputError}
                    />
                }
            }
            case "boolean":
                return <BooleanField 
                    key={property}
                    schema={schema}
                    value={value}
                    onChange={onChange}
                />
            case "color": 
                return <ColorSelectionField
                    key={property}
                    schema={schema}
                    value={value}
                    onChange={onChange}
                />
            case "list": {
                if (schema.multioption) {
                    return <MultipleSelectField
                        key={property}
                        schema={schema}
                        value={value}
                        onChange={onChange}
                        hasInputError={hasInputError}
                    />
                } else {
                    return <DropdownListField
                        key={property}
                        schema={schema}
                        value={value}
                        onChange={onChange}
                    />
                } 
            }
            default:
                return null
        }
    })

    const graphicPackageForm = (
        <form className="graphic-package-form">
            <div className="input-container">
                <label htmlFor="" className="input-title">Template</label>
                <SmallDropdown 
                    value={selectedTemplate}
                    options={templatesNames}
                    onChange={(v) => setSelectedTemplate(v)}/>
            </div>
            <div className="input-container">
                <label className="input-title">Package name</label>
                <input
                    type="text"
                    onChange={(e) => setPackageName(e.target.value)}
                    value={packageName}/>
                {hasInputError && showErrorMessage("Package name min 5 characters", packageName.length < 5)}
            </div>
            {packageParamsInput}
        </form>
    )

    return (
        <div className="create-graphic-package-cont">
            {graphicPackageForm}
            <div className="confirm-cancel-btn-cont between margin-top">
                <button type="button" disabled={hasInputError} onClick={onCreatePackage} className="green-btn">Create</button>
                <button type="button" onClick={resetCreatePackage}>Cancel</button>
            </div>
        </div>
    )
}

export default GraphicPackageForm