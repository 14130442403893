import React, {useState} from "react";
import "./EventListing.css";
import {getFullPath, infrequentCacheConf, useBackend} from "../../../utility/Backend";
import LoadingScreen from "../../../components/LoadingScreen";
import Dropdown from "../../../components/Dropdown";
import {capitalizeFirstLetter, useUpdateSearchParams} from "../../../utility/Utilities";
import EventEntry from "../../../components/EventEntry";
import { useAddVideoToAction } from "../../../utility/Utilities";
import Config from "../../../utility/Config";
import SearchResults from "../../../components/SearchResults";
import {useMediaQuery} from "react-responsive";
import { PiListChecksBold } from "react-icons/pi";
import { IoMdOptions } from "react-icons/io";
import FullscreenFilter from "../../../components/FullscreenFilter";

//FIXME Probably better if this was defined somewhere else, as it's being reused, but refactor later
export function createDateFilter (query, searchParams, updateSearchParams) {

    const seasons = Config.seasons;
    let season = searchParams.get("season") || "all";
    if (season !== "all") {
        // Sanitize input to only allow pre-selected options
        if (!seasons.includes(season)) season = seasons[0];
        const [start, end] = Config.seasonInterval(season)
        query["from_date"] = start.toISOString();
        query["to_date"] = end.toISOString();
    }

    return [query, (
        <div>
            <Dropdown
                centered
                title="Season"
                special="all"
                fallback="all"
                selected={season}
                options={seasons}
                onChange={v => updateSearchParams("season", v)}/>
        </div>
    )];
}

function createTeamFilter (query, team, teams, updateSearchParams) {

    if (team) {
        query["tags"] = {team: {id: team}};
    }
    if (teams === null) {
        return [query, null];
    }

    let options = Object.keys(teams);
    options.sort((a,b) => (teams[a].name > teams[b].name) ? 1 : -1);

    return [query, (
        <div>
            <Dropdown
                title="Team"
                special="all"
                fallback="all"
                selected={team}
                options={options}
                render={(id) => teams[id].name}
                btnRender={(id) => teams[id].short_name || teams[id].name}
                onChange={v => updateSearchParams({"team": v, "player": null,"player_action": null})}/>
        </div>
    )];
}

const ignoredTags = ["end of game", "start phase", "end phase", "throw-in", "highlights"];
const ignoredProps = ["after set piece"]
const oppositeTeamProp = ["offending player", "keeper"];
function createTagFilters (query, tags, players, searchParams, updateSearchParams) {

    // For this function to make any sense, have a look at the /tags API endpoint, which defines all possible tags

    let filters = [];
    let tag = searchParams.get("tag");
    const validTags = Object.keys(tags.tags).filter(t => !ignoredTags.includes(t));
    // Sanitize input to only allow the tags we wish to support
    if (tag && !validTags.includes(tag)) tag = null;

    const queryKeys = []; // Gets updated further down as we iterate through the props

    function onSwapTag (v) {
        // Delete all the query parameters specific to this tag, if they exist
        console.log("Swapping tag from", tag, "to", v);
        const q = queryKeys.reduce((agg, key) => {
            agg[key] = null;
            return agg;
        }, {"tag": v})
        updateSearchParams(q);
    }

    filters.push(
        <div key="tag">
            <Dropdown
                centered
                title="Tag"
                fallback="all"
                special="all"
                selected={tag}
                options={validTags}
                onChange={onSwapTag} />
        </div>
    );
    if (tag === null) return [query, filters];

    // We make a copy. It is possible that team is already set
    const q = {...query["tags"], action: tag};

    let playerList = [];
    if (players !== null) {
        playerList = Object.keys(players);
        playerList.sort((a,b) => (players[a].name > players[b].name) ? 1 : -1);
    }

    // Add player properties
    let playerProps = tags.tags[tag].reduce((agg, {name, type}) => {
        if (type === "player") agg.push(name);
        return agg;
    }, []);

    const renderPlayer = (id) => (
        <span><span className="inline-shirt">{players[id].shirt_number || ""}</span> {players[id].name}</span>
    );
    const renderShortPlayer = (id) => (
        <span><span className="inline-shirt">{players[id].shirt_number || ""}</span> {players[id].last_name || players[id].name}</span>
    );

    if (playerProps.length > 0) {
        queryKeys.push("player_action"); // We keep "player"

        if (players === null) {
            filters.push(
                <div key="player">
                    <Dropdown
                        title="Player"
                        disabled
                        fallback="Pick team" />
                </div>
            );
        } else {
            const playerId = parseInt(searchParams.get("player"), 10) || null;
            filters.push(
                <div key="player">
                    <Dropdown
                        title="Player"
                        fallback="all"
                        special="all"
                        selected={playerId}
                        options={playerList}
                        btnRender={renderShortPlayer}
                        render={renderPlayer}
                        onChange={v => updateSearchParams("player", v)} />
                </div>
            );

            if (playerId) {
                const fallback = playerProps[0];
                if (playerProps.length === 1) {
                    q[fallback] = {id: playerId};
                } else {
                    let prop = searchParams.get("player_action") || fallback;
                    if (!playerProps.includes(prop)) prop = fallback;
                    q[prop] = {id: playerId};
                    filters.push(
                        <div key="player_action">
                            <Dropdown
                                title="Role"
                                fallback={fallback}
                                selected={prop}
                                options={playerProps}
                                onChange={v => updateSearchParams("player_action", v)} />
                        </div>
                    );
                }
            }
        }
    }

    // Add custom tags. These are easy, they define all the possible options as a list of strings
    tags.tags[tag].forEach((({name, type}) => {
        const typedef = tags.types[type];
        if (!typedef.custom || typedef.options.length === 0 || ignoredProps.includes(name)) return;

        const key = `${tag}_${name}`;
        queryKeys.push(key);
        let value = searchParams.get(key);
        if (value && (!typedef.options.includes(value))) value = null;

        if (value) q[name] = {value};
        filters.push(
            <div key={key}>
                <Dropdown
                    centered
                    title={capitalizeFirstLetter(name)}
                    fallback="all"
                    special="all"
                    selected={value}
                    options={typedef.options}
                    onChange={v => updateSearchParams(key, v)} />
            </div>
        );
    }));

    // If we're searching for own goals and have set a scorer
    if (q.action === "goal" && q["shot type"] && q["shot type"]["value"] === "own goal" && q["scorer"]) {
        delete q["team"];
    }

    // Some properties work on the opposite team. In this case we cannot find them if we include team prop
    if (oppositeTeamProp.some(p => q[p])) {
        delete q["team"];
    }

    return [{...query, tags: q}, filters];
}

function EventListing ({tags}) {

    const [
        selectedVideos, 
        selectAll,
        addVideoToList, 
        removeVideoFromList, 
        clearSelectedList, 
        handleSelectAll 
    ] = useAddVideoToAction();
    
    const [mobileShowFilters, setMobileShowFilters] = useState(false)
    const [mobileShowAction, setMobileShowAction] = useState(false)
    const [searchParams, updateSearchParams,] = useUpdateSearchParams();

    let team = parseInt(searchParams.get("team"), 10) || null;
    let apiQuery = {};
    let dateFilter, teamFilter, tagFilters;

    [apiQuery, dateFilter] = createDateFilter(apiQuery, searchParams, updateSearchParams);

    let teams = null;
    const {data: activeTeams} = useBackend(
        "/team/active_teams",
        apiQuery,
        infrequentCacheConf);
    if (activeTeams) {
        teams = activeTeams["teams"].reduce((agg, t) => {agg[t.id] = t; return agg;}, {});
        if (team && !teams[team]) team = null;
    }

    let players = null;
    const {data: activePlayers} = useBackend(
        team ? ("/team/" + team + "/active_players") : null,
        apiQuery,
        infrequentCacheConf);
    if (activePlayers) {
        players = activePlayers["active_players"].reduce((agg, p) => {agg[p.player.id] = p.player; return agg;}, {});
    }

    [apiQuery, teamFilter] = createTeamFilter(apiQuery, team, teams, updateSearchParams);
    [apiQuery, tagFilters] = createTagFilters(apiQuery, tags, players, searchParams, updateSearchParams);

    let fullPath;
    // If we're missing data, dont perform the request
    if (teams === null || (team && players === null)) fullPath = null;
    else fullPath = getFullPath("/event", apiQuery);

    const editing = searchParams.get("editing");
    let editingType, editingId;
    if (editing) [editingType, editingId] = editing.split("_");

    const handleMobileAction = () => {
        if (selectedVideos.length !== 0) clearSelectedList()
        setMobileShowAction(!mobileShowAction)
    }

    const renderItem = (e, idx) => (
        <EventEntry key={e.id}
                    event={e}
                    active={e.id === editingId && editingType === "event"}
                    tabIndex={20+idx} 
                    selectedVideos={selectedVideos}
                    selectAll={selectAll}
                    addVideoToList={addVideoToList}
                    removeVideoFromList={removeVideoFromList}
                    mobileShowAction={mobileShowAction}/>
    )

    const mobileDevice = useMediaQuery({maxWidth: 768})

    // make this a component, and also select videos, maybe (return [state, html, etc])
    const mobileVideoFilters = mobileDevice && (
        <FullscreenFilter showFilter={mobileShowFilters} onSetFilter={setMobileShowFilters}>
            <>
                {dateFilter}
                {teamFilter}
                {tagFilters}
            </>
        </FullscreenFilter>
    )

    const resultsOptionsMobile = mobileDevice && (
        <div className="results-options-mobile">
            <div onClick={handleMobileAction}>
                <PiListChecksBold/>
                {mobileShowAction ? "Cancel selection"  : "Select videos"}
            </div>
            <div onClick={() => setMobileShowFilters(true)}><IoMdOptions/> Filters</div>
        </div>
    )

    return (
        <div className="event-listing">
            <div className="event-filters">
                {dateFilter}
                {teamFilter}
                {tagFilters}
            </div>
            {mobileVideoFilters}
            {resultsOptionsMobile}
            <SearchResults 
                key={fullPath} 
                path={fullPath} 
                render={renderItem}
                selectedVideos={selectedVideos} 
                clearSelectedList={clearSelectedList}
                selectAll={selectAll}
                handleSelectAll={handleSelectAll}
                setMobileShowAction={setMobileShowAction}
                setMobileShowFilters={setMobileShowFilters}/>
        </div>
    );
}

export default function EventListingWrapper () {
    const {data} = useBackend("/tag", undefined, {revalidateIfStale: false, revalidateOnFocus: false});
    if (!data) return <LoadingScreen />;

    return <EventListing tags={data}/>;
}
